import {
  onMount,
  createResource,
  Show,
  For,
  createSignal,
  Switch,
  Match,
  onCleanup,
  createEffect,
} from "solid-js";
import { Dynamic } from "solid-js/web";
import { A } from "@solidjs/router";
import { getBuilderContentByModel } from "~/utils/builder";
import {
  createDate,
  createCountdownFromNow,
  createTimeDifferenceFromNow,
} from "@solid-primitives/date";
import type { Countdown } from "@solid-primitives/date";
import { builderImage } from "~/utils/builder";
import { Carousel } from "~/components/ui";
import Button from "~/components/Button";
import { Icon } from "solid-heroicons";
import { documentArrowDown } from "solid-heroicons/outline";
import {
  playPause,
  speakerWave,
  speakerXMark,
  play,
  pause,
} from "solid-heroicons/solid";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import type { Component, FlowComponent } from "solid-js";

export default function NewCollection() {
  const [content] = createResource(async () => {
    const data = await getBuilderContentByModel(
      "new-collection-launch",
      9999,
      0
    );

    return data?.[0]?.data as PageContent;
  });

  const [date] = createDate(
    "Thur Jul 18 2024 11:00:00 GMT-0400 (Eastern Daylight Saving Time)"
  );
  const [to] = createSignal(Math.floor(date().getTime()));
  const [countdown] = createCountdownFromNow(to);
  const [diff] = createTimeDifferenceFromNow(to);


  const shadow = "shadow-[0_0_8px_-1px_rgba(0,0,0,0.2)]";

  const CD: Component<{ countdown: Countdown; diff: () => number }> = (
    props
  ) => {
    return (
      <>
        <script
          src="https://cdn.jsdelivr.net/npm/add-to-calendar-button@2"
          async
          defer
        />
        <section class="flex flex-col justify-center items-center bg-white min-h-[80vh] p-4">
          <div class="bg-roma-grey py-10 px-12 text-4xl md:text-7xl font-bold flex flex-col justify-center gap-3 rounded-3xl">
            <Show
              when={props.diff() >= 0}
              fallback={
                <div class="text-xl md:text-3xl text-center">
                  <p>
                    <button
                      class="text-roma-blue"
                      onClick={() => window.location.reload()}
                    >
                      Refresh
                    </button>{" "}
                    this window to view the new Vola collection!
                  </p>
                </div>
              }
            >
              <p class="text-xl md:text-3xl text-center ">
                Experience the Summer 2024 Vola Collection Here <br /> July 18,
                11AM EDT
              </p>
              <div class="flex justify-center md:gap-2">
                <div class="flex flex-col items-center">
                  <p>{props.countdown.days!.toString().padStart(2, "0")}</p>
                  <p class="text-lg">Days</p>
                </div>
                <p class="-mt-1.5">:</p>
                <div class="flex flex-col items-center">
                  <p>{props.countdown.hours!.toString().padStart(2, "0")}</p>
                  <p class="text-lg">Hours</p>
                </div>
                <p class="-mt-1.5">:</p>
                <div class="flex flex-col items-center">
                  <p>{props.countdown.minutes!.toString().padStart(2, "0")}</p>
                  <p class="text-lg">Minutes</p>
                </div>
                <p class="-mt-1.5">:</p>
                <div class="flex flex-col items-center">
                  <p>{props.countdown.seconds!.toString().padStart(2, "0")}</p>
                  <p class="text-lg">Seconds</p>
                </div>
              </div>
            </Show>

            {/* @ts-ignore */}
            <add-to-calendar-button
              class="mx-auto mt-2"
              name="Roma Summer 2024 Collection Launch"
              startDate="2024-07-18"
              startTime="11:00"
              endTime="12:00"
              timeZone="Canada/Eastern"
              location="https://www.romamoulding.com/new"
              description="Experience the Summer 2024 Collection:[br]→ [url]https://www.romamoulding.com/new[/url]"
              options="'Apple','Google','iCal','Outlook.com','Microsoft 365','Microsoft Teams','Yahoo'"
              lightMode="bodyScheme"
              trigger="click"
              label="Add to Calendar"
              iCalFileName="Summer-Collection-Launch"
              organizer="Roma Moulding|info@romamoulding.com"
              size="3"
            />
          </div>
        </section>
      </>
    );
  };

  const VolaLogo: Component<{ width?: number }> = (props) => {
    return (
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        width={props.width || 80}
        viewBox="0 0 1080 550"
        style="enable-background:new 0 0 1080 550;"
      >
        <g>
          <path
            fill="currentColor"
            d="M291.56,449.67L168.82,132.09h69.52l83.69,223.59h1.29l70.81-223.59h21.46L294.57,449.67H291.56z"
          />
          <path
            fill="currentColor"
            d="M486.53,214.06c71.67,0,96.56,51.07,96.56,110.3c0,55.36-21.03,110.3-101.28,110.3
      c-75.53,0-96.99-51.07-96.99-108.58C384.82,271.57,411.86,214.06,486.53,214.06z M483.96,427.35c31.33,0,35.19-47.21,35.62-102.57
      c-0.43-62.23-6.87-103.43-35.62-103.43c-27.89,0-35.19,46.35-35.19,105.15C448.77,381.43,454.78,427.35,483.96,427.35z"
          />
          <path
            fill="currentColor"
            d="M614.91,431.64l0.43-331.31h59.23v331.31H614.91z"
          />
          <path
            fill="currentColor"
            d="M911.18,429.93c-10.3,3.86-23.6,5.58-34.76,5.58c-3,0-5.58-0.43-8.15-0.43c-20.6-1.29-36.91-10.73-42.92-36.05
      c-20.17,24.03-36.91,34.76-60.51,36.05c-2.58,0.43-5.15,0.43-8.15,0.43c-29.61-0.86-53.65-21.89-53.65-58.37
      c0-54.08,55.36-76.82,105.57-77.68c-6.01-44.63-18.88-71.67-51.07-71.67c-7.3,0-16.74,1.29-26.61,3.86l-1.72-7.3
      c9.87-4.29,24.03-7.73,38.19-9.87c6.01-0.43,12.02-0.86,18.03-0.86c42.06,0,68.67,15.88,77.68,58.37
      c11.59,60.08,17.17,97.42,22.32,121.88c4.72,20.17,9.44,27.04,18.45,28.75c2.15,0,4.29,0,6.44,0L911.18,429.93z M788.01,407.61
      c11.16,0,24.89-5.58,35.62-16.31c-3.86-18.03-8.15-51.5-14.16-84.97c-41.2,4.72-48.07,41.2-48.07,60.08
      C761.4,393.02,772.13,407.61,788.01,407.61z"
          />
        </g>
      </svg>
    );
  };

  const Card: Component<
    SliderCardContent & {
      enableLinking?: boolean;
      class?: string;
      index?: () => number;
    }
  > = (props) => {
    let cardRef: any;

    onMount(() => {
      if (props.index) {
        gsap.fromTo(
          cardRef,
          {
            opacity: 0,
            y: "30px",
          },
          {
            opacity: 1,
            y: 0,
            scrollTrigger: { trigger: cardRef, start: "top bottom" },
            delay: props.index() * 0.1,
          }
        );
      }
    });

    return (
      <Dynamic
        ref={(ref: typeof A | HTMLDivElement) => (cardRef = ref)}
        component={props.href && props.enableLinking ? A : "div"}
        href={props.href && props.enableLinking ? props.href : undefined}
        class="snap-start pl-2 pr-2 first:ml-0last:mr-0 shrink-0  w-[275px] my-4"
        classList={{ [`${props.class}`]: !!props.class }}
      >
        <div
          class={`flex flex-col bg-white ${shadow} rounded-xl p-4 gap-2 h-full  group`}
        >
          <div
            class={`rounded-lg flex items-center justify-center overflow-hidden ${
              props.enableLinking
                ? "border border-transparent group-hover:border-gray-200"
                : ""
            } `}
          >
            <img
              src={builderImage({
                src: props.image,
                width: 275,
                format: "webp",
              })}
              alt=""
              class="bg-roma-grey w-full h-full"
            />
          </div>
          <p class="text-xl font-bold">{props.heading}</p>
          <Switch>
            <Match when={props.cardStyle === "simple"}>
              <p class="text-sm leading-5 pb-1">{props.copy}</p>
            </Match>
            <Match when={props.cardStyle === "product"}>
              <div class="text-roma-medium-grey">
                <p>{props.colourName}</p>
                <p class="fraction">{props.dimensions}</p>
              </div>
            </Match>
          </Switch>
        </div>
      </Dynamic>
    );
  };

  const ShrinkingWrapper: FlowComponent<{ class?: string }> = (props) => {
    let container: HTMLDivElement;

    onMount(() => {
      gsap.to(container, {
        marginLeft: "0",
        marginRight: "0",
        borderRadius: "0.75rem",
        boxShadow: "0 0 24px -1px rgba(0,0,0,0.2)",
        scrollTrigger: {
          trigger: container,
          start: "top top",
          // end: "bottom 200px",
          end: () => {
            if (window) {
              const viewport = window.innerWidth;
              if (viewport <= 500) {
                return "bottom 0px";
              }
            }

            return "bottom 200px";
          },
          scrub: 1,
        },
      });
    });

    return (
      <div
        class={`mx-auto overflow-hidden ${
          props.class ? props.class : ""
        } max-xs:-mx-8 xs:-mx-8 md:-mx-20 lg:-mx-36`}
        ref={(ref) => (container = ref)}
      >
        {props.children}
      </div>
    );
  };

  const CustomizedCarousel: FlowComponent<{ title?: string }> = (props) => {
    return (
      <section class="my-6">
        <h2 class="text-4xl font-bold">{props.title}</h2>
        <div class="-mx-8 xs:-mx-8 md:-mx-20 lg:-mx-36">
          <Carousel
            rootClass="!space-y-2"
            containerClass="!pb-0 scrollbar-none"
            buttonLocation="bottom"
            buttonContainerClass="mr-8 xs:mr-8 md:mr-20 lg:mr-36"
            buttonStyle="dark"
            disableFade={true}
          >
            <div class="snap-start pl-2 pr-2 first:ml-0 last:mr-0 shrink-0 w-8 xs:w-8 md:w-20 lg:w-36 " />
            {props.children}
            <div class="snap-start pl-2 pr-2 first:ml-0last:mr-0 shrink-0  w-8 xs:w-8 md:w-20 lg:w-36" />
          </Carousel>
        </div>
      </section>
    );
  };

  const ImageWithTextBanner: Component<
    Banner & { imagePosition: "left" | "right" }
  > = (props) => {
    let containerRef: HTMLDivElement;
    let contentBoxRef: HTMLDivElement;

    onMount(() => {
      gsap.fromTo(
        contentBoxRef,
        {
          opacity: 0,
          y: "30px",
        },
        {
          opacity: 1,
          y: 0,
          scrollTrigger: {
            trigger: containerRef,
            start: "top bottom",
          },
          duration: 1,
        }
      );
    });

    return (
      <div
        ref={(ref) => (containerRef = ref)}
        class={`${shadow} rounded-xl p-6 md:p-10 gap-4 flex flex-col-reverse ${
          props.imagePosition === "right"
            ? "lg:flex-row-reverse"
            : "lg:flex-row"
        }  items-stretch min-h-min`}
      >
        <div class="basis-1/2 shrink-0">
          <img
            src={builderImage({ src: props.image, width: 900, format: "webp" })}
            alt=""
            class="object-cover w-full h-full"
          />
        </div>
        <div
          ref={(ref) => (contentBoxRef = ref)}
          class={`basis-1/2  flex flex-col justify-between lg:justify-evenly gap-6 lg:gap-2   ${
            props.imagePosition === "right"
              ? "lg:items-start lg:text-left lg:pr-8"
              : "lg:items-end lg:text-right lg:pl-8"
          }`}
        >
          <h3 class="text-7xl md:text-[5rem] font-bold  md:leading-[5.5rem]">
            {props.heading}
          </h3>
          <p class="text-base xl:text-lg font-light">{props.copy}</p>
          <Button href={props.buttonHref} noScroll={true}>
            <span class="px-4 font-light">{props.buttonText}</span>
          </Button>
        </div>
      </div>
    );
  };

  const Banner: Component<MainBanner> = (props) => {
    return (
      <div
        class={`${shadow} rounded-3xl overflow-hidden flex flex-col lg:flex-row select-none `}
      >
        <div class="basis-2/3 shrink-0 select-none">
          <img
            src={builderImage({
              src: props.desktopImage,
              width: 1500,
              format: "webp",
            })}
            alt=""
            class="hidden lg:block w-full h-full object-cover"
            fetchpriority="high"
          />
          <img
            src={builderImage({
              src: props.mobileImage,
              width: 900,
              format: "webp",
            })}
            alt=""
            class="block lg:hidden w-full h-full object-cover"
            fetchpriority="high"
          />
        </div>
        <div class="flex flex-col gap-4 basis-1/3 shrink-0  justify-center text-center items-center lg:text-right lg:items-end p-6 lg:p-10 lg:!pl-0 ">
          <div class="w-[50%] lg:w-[80%] max-w-[275px] shrink">
            <img src={props.headerImage} alt="" />
          </div>
          <p class="font-light   text-xl  xl:text-3xl italic text-roma-medium-grey">
            {props.subheaderText}
          </p>
          <p class="max-w-sm max-lg:text-sm xl:text-lg font-light">
            {props.copyText}
          </p>
          <Button href={props.buttonHref}>
            <span class="px-4 font-light">{props.buttonText}</span>
          </Button>
        </div>
      </div>
    );
  };

  const Callout: Component<Callout & { index?: () => number }> = (props) => {
    let calloutRef: HTMLDivElement;

    onMount(() => {
      if (props.index) {
        gsap.fromTo(
          calloutRef,
          {
            opacity: 0,
            y: "30px",
          },
          {
            opacity: 1,
            y: 0,
            scrollTrigger: { trigger: calloutRef, start: "top bottom" },
            delay: props.index() * 0.2,
            duration: 0.75,
          }
        );
      }
    });

    return (
      <div
        ref={(ref) => (calloutRef = ref)}
        class="flex flex-col items-center gap-3 text-center p-3"
      >
        <div>
          <img src={props.image} alt="" width="168" />
        </div>
        <h4 class="text-3xl font-bold">{props.heading}</h4>
        <p class="font-light">{props.copy}</p>
      </div>
    );
  };

  const Quotation: Component<{ quote: string; author: string }> = (props) => {
    return (
      <div class="flex justify-center items-center text-center py-16 md:py-20 px-5">
        <blockquote class="text-3xl md:text-5xl">
          <p class="font-bold">"{props.quote}"</p>
          <Show when={props.author}>
            <cite class="text-base pt-2 not-italic font-medium md:text-xl">
              - {props.author}
            </cite>
          </Show>
        </blockquote>
      </div>
    );
  };

  const Video = (props: { path: string }) => {
    let vidRef: HTMLVideoElement;
    const [isMuted, setIsMuted] = createSignal(true);
    const [isPlaying, setIsPlaying] = createSignal(true);
    const togglePlayPause = () => {
      const play = () => {
        if (vidRef) {
          vidRef.play();
          setIsPlaying(true);
        }
      };

      const pause = () => {
        if (vidRef) {
          vidRef.pause();
          setIsPlaying(false);
        }
      };

      if (vidRef) {
        vidRef.paused == true ? play() : pause();
      }
    };
    const toggleMute = () => {
      if (vidRef) {
        setIsMuted(!isMuted());
      }
    };
    onMount(() => {
      gsap.registerPlugin(ScrollTrigger);

      if (!vidRef) return;
      // stop the video when it exits the viewport, replay when it re-enters
      ScrollTrigger.create({
        trigger: vidRef,
        start: "top bottom",
        end: "bottom top",
        onEnter: () => {
          vidRef.play();
          setIsPlaying(true);
        },
        onLeave: () => {
          vidRef.pause();
          setIsPlaying(false);
        },
        onEnterBack: () => {
          vidRef.play();
          setIsPlaying(true);
        },
        onLeaveBack: () => {
          vidRef.pause();
          setIsPlaying(false);
        },
      });
    });

    onCleanup(() => {
      if (vidRef) {
        vidRef.pause();
        vidRef.currentTime = 0;
        vidRef.src = "";
      }
    });

    return (
      <div class="w-full h-full relative">
        <video
          ref={(ref) => (vidRef = ref)}
          class="w-full h-full object-cover"
          src={props.path}
          autoplay
          loop
          muted={isMuted()}
        />
        <div class="absolute bottom-5 left-5 text-white mix-blend-exclusion flex items-center gap-2">
          <button onClick={togglePlayPause}>
            <Icon path={isPlaying() ? pause : play} class="w-8 h-8" />
          </button>
          <button onClick={toggleMute}>
            <Icon
              path={isMuted() ? speakerWave : speakerXMark}
              class="w-8 h-8"
            />
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Show
        // when={false}
        when={content()}
        fallback={
          <>
            <div class="w-full min-h-[70vh] flex items-center justify-center p-3">
              <div class=" bg-roma-grey rounded-lg flex items-center justify-center text-center p-8">
                Check back soon for our next collection launch!
              </div>
            </div>
          </>
        }
      >
        <div class="w-full max-w-[1824px] mx-auto">
          <div class=" mx-8 xs:mx-8 md:mx-20 lg:mx-36 flex flex-col  gap-y-10 md:gap-y-16 mb-20">
            {/* <div class="text-3xl fixed top-1.5 left-1 text-white mix-blend-exclusion z-10 flex items-center">
                <VolaLogo width={85} />
              </div> */}
            {/* VIDEO 1 */}
            <Show when={content()?.videoPath1}>
              <ShrinkingWrapper>
                <Video path={content()!.videoPath1} />
              </ShrinkingWrapper>
            </Show>
            {/* BANNER 1 */}
            <Show when={content()?.banner1}>
              <ImageWithTextBanner
                imagePosition="left"
                {...(content()?.banner1 as Banner)}
              />
            </Show>

            {/* SLIDER 1 */}
            <Show when={content()?.slider1}>
              <CustomizedCarousel title={content()?.slider1.heading}>
                <For each={content()?.slider1.slider}>
                  {(card, i) => (
                    <Card
                      {...card}
                      index={i}
                      enableLinking={content()?.slider1.enableLinking}
                      class={i() === 0 ? "!ml-0 !pl-0" : ""}
                    />
                  )}
                </For>
              </CustomizedCarousel>
            </Show>
            {/* 2 */}
            {/* VIDEO 2 */}
            <Show when={content()?.videoPath2}>
              <ShrinkingWrapper>
                <Video path={content()!.videoPath2} />
              </ShrinkingWrapper>
            </Show>
            {/* BANNER 2 */}
            <Show when={content()?.banner2}>
              <ImageWithTextBanner
                imagePosition="left"
                {...(content()?.banner2 as Banner)}
              />
            </Show>
            {/* SLIDER 2 */}
            <Show when={content()?.slider2}>
              <CustomizedCarousel title={content()?.slider2.heading}>
                <For each={content()?.slider2.slider}>
                  {(card, i) => (
                    <Card
                      {...card}
                      index={i}
                      enableLinking={content()?.slider2.enableLinking}
                      class={i() === 0 ? "!ml-0 !pl-0" : ""}
                    />
                  )}
                </For>
              </CustomizedCarousel>
            </Show>
            {/* 3 */}
            {/* VIDEO 3 */}
            <Show when={content()?.videoPath3}>
              <ShrinkingWrapper>
                <Video path={content()!.videoPath3} />
              </ShrinkingWrapper>
            </Show>
            {/* BANNER 3 */}
            <Show when={content()?.banner3}>
              <ImageWithTextBanner
                imagePosition="left"
                {...(content()?.banner3 as Banner)}
              />
            </Show>
            {/* SLIDER 3 */}
            <Show when={content()?.slider3}>
              <CustomizedCarousel title={content()?.slider3.heading}>
                <For each={content()?.slider3.slider}>
                  {(card, i) => (
                    <Card
                      {...card}
                      index={i}
                      enableLinking={content()?.slider3.enableLinking}
                      class={i() === 0 ? "!ml-0 !pl-0" : ""}
                    />
                  )}
                </For>
              </CustomizedCarousel>
            </Show>
            {/* PHOTOFRAME BANNER */}
            <ImageWithTextBanner
              imagePosition="left"
              {...(content()?.banner4 as Banner)}
            />
            {/* SLIDER 4 */}
            <Show when={content()?.slider4}>
              <CustomizedCarousel title={content()?.slider4.heading}>
                <For each={content()?.slider4.slider}>
                  {(card, i) => (
                    <Card
                      {...card}
                      index={i}
                      enableLinking={content()?.slider4.enableLinking}
                      class={i() === 0 ? "!ml-0 !pl-0" : ""}
                    />
                  )}
                </For>
              </CustomizedCarousel>
            </Show>
            {/* QUOTATION */}
            <Quotation {...(content()?.quotation1 as Quotation)} />
            {/* CALLOUTS */}
            <section class="grid md:grid-cols-3 mb-10 gap-3">
              <For each={content()?.calloutRow}>
                {(callout, i) => <Callout {...callout} index={i} />}
              </For>
            </section>
            {/* DOWNLOAD SPEC SHEET */}
            <Show when={content()?.assetDownloads?.length}>
              <div class="flex flex-wrap justify-end gap-1">
                <For each={content()?.assetDownloads}>
                  {(asset) => (
                    <div class="flex justify-end ">
                      <a
                        class="flex items-center gap-2 border border-gray-300 rounded-md px-3 py-2 text-sm text-roma-medium-grey hover:bg-roma-grey hover:text-roma-dark-grey"
                        href={asset.file}
                        download
                        target="_blank"
                      >
                        <Icon path={documentArrowDown} class="w-5 h-5" />
                        <span>{asset.label ?? "Download Spec Sheet"}</span>
                      </a>
                    </div>
                  )}
                </For>
              </div>
            </Show>
          </div>
        </div>
      </Show>
    </>
  );
}

// * TYPES * //

interface SliderCardContent {
  image: string;
  heading: string;
  copy: string;
  href: string;
  cardStyle: "simple" | "product";
  colourName: string;
  dimensions: string;
}

interface Banner {
  image: string;
  buttonText: string;
  buttonHref: string;
  heading: string;
  copy: string;
}

interface MainBanner {
  mobileImage: string;
  buttonText: string;
  buttonHref: string;
  headerImage: string;
  copyText: string;
  desktopImage: string;
  subheaderText: string;
}

interface Slider {
  slider: SliderCardContent[];
  heading: string;
  enableLinking?: boolean;
}

interface Callout {
  image: string;
  heading: string;
  copy: string;
}

interface Quotation {
  quote: string;
  author: string;
}

// interface PageContent {
//   mainBanner: MainBanner;
//   banner1: Banner;
//   banner2: Banner;
//   slider1: Slider;
//   slider2: Slider;
//   calloutRow: Callout[];
//   video: string;
//   videoPath: string;
//   launchDate: string;
//   quotation1: Quotation;
//   assetDownload: string;
// }

interface PageContent {
  videoPath1: string;
  videoPath2: string;
  videoPath3: string;
  // mainBanner: MainBanner;
  banner1: Banner;
  banner2: Banner;
  banner3: Banner;
  banner4: Banner;
  slider1: Slider;
  slider2: Slider;
  slider3: Slider;
  slider4: Slider;
  calloutRow: Callout[];
  // video: string;
  launchDate: string;
  quotation1: Quotation;
  assetDownloads: { label: string; file: string }[];
}

// * END TYPES * //
